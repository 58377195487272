import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2726369b = () => interopDefault(import('../pages/4wg8a/index.vue' /* webpackChunkName: "pages/4wg8a/index" */))
const _52ccde16 = () => interopDefault(import('../pages/adblock.vue' /* webpackChunkName: "pages/adblock" */))
const _6ae5f2cc = () => interopDefault(import('../pages/app.vue' /* webpackChunkName: "pages/app" */))
const _67e6c272 = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _0f962f32 = () => interopDefault(import('../pages/copyright.vue' /* webpackChunkName: "pages/copyright" */))
const _140a2997 = () => interopDefault(import('../pages/donate/index.vue' /* webpackChunkName: "pages/donate/index" */))
const _62b60ac5 = () => interopDefault(import('../pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _46ffaf3a = () => interopDefault(import('../pages/films-4k.vue' /* webpackChunkName: "pages/films-4k" */))
const _7fca0d26 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _5b6b941a = () => interopDefault(import('../pages/name/index.vue' /* webpackChunkName: "pages/name/index" */))
const _3016ce04 = () => interopDefault(import('../pages/popular.vue' /* webpackChunkName: "pages/popular" */))
const _24dd0f33 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _7b011457 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _5f4fa7a6 = () => interopDefault(import('../pages/rooms/index.vue' /* webpackChunkName: "pages/rooms/index" */))
const _789fd5c7 = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _5d5c234e = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _a9a2ef08 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _3fa6fb9f = () => interopDefault(import('../pages/4wg8a/chat.vue' /* webpackChunkName: "pages/4wg8a/chat" */))
const _7888c114 = () => interopDefault(import('../pages/4wg8a/donate.vue' /* webpackChunkName: "pages/4wg8a/donate" */))
const _604605cc = () => interopDefault(import('../pages/4wg8a/feedback.vue' /* webpackChunkName: "pages/4wg8a/feedback" */))
const _6d01af34 = () => interopDefault(import('../pages/4wg8a/kino.vue' /* webpackChunkName: "pages/4wg8a/kino" */))
const _73c842d3 = () => interopDefault(import('../pages/4wg8a/unsubs.vue' /* webpackChunkName: "pages/4wg8a/unsubs" */))
const _4cbb6746 = () => interopDefault(import('../pages/4wg8a/vmeste.vue' /* webpackChunkName: "pages/4wg8a/vmeste" */))
const _34933706 = () => interopDefault(import('../pages/auth/error.vue' /* webpackChunkName: "pages/auth/error" */))
const _d59ba3fe = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _10db6648 = () => interopDefault(import('../pages/profile/favorites.vue' /* webpackChunkName: "pages/profile/favorites" */))
const _fbf51a50 = () => interopDefault(import('../pages/profile/ref.vue' /* webpackChunkName: "pages/profile/ref" */))
const _02bcef86 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1a5afe32 = () => interopDefault(import('../pages/catalog/_id/index.vue' /* webpackChunkName: "pages/catalog/_id/index" */))
const _f93c0b26 = () => interopDefault(import('../pages/favorites/_id.vue' /* webpackChunkName: "pages/favorites/_id" */))
const _0c611f5e = () => interopDefault(import('../pages/name/_id/index.vue' /* webpackChunkName: "pages/name/_id/index" */))
const _5dbf00de = () => interopDefault(import('../pages/rooms/_id/index.vue' /* webpackChunkName: "pages/rooms/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/4wg8a",
    component: _2726369b,
    name: "4wg8a"
  }, {
    path: "/adblock",
    component: _52ccde16,
    name: "adblock"
  }, {
    path: "/app",
    component: _6ae5f2cc,
    name: "app"
  }, {
    path: "/catalog",
    component: _67e6c272,
    name: "catalog"
  }, {
    path: "/copyright",
    component: _0f962f32,
    name: "copyright"
  }, {
    path: "/donate",
    component: _140a2997,
    name: "donate"
  }, {
    path: "/favorites",
    component: _62b60ac5,
    name: "favorites"
  }, {
    path: "/films-4k",
    component: _46ffaf3a,
    name: "films-4k"
  }, {
    path: "/help",
    component: _7fca0d26,
    name: "help"
  }, {
    path: "/name",
    component: _5b6b941a,
    name: "name"
  }, {
    path: "/popular",
    component: _3016ce04,
    name: "popular"
  }, {
    path: "/privacy",
    component: _24dd0f33,
    name: "privacy"
  }, {
    path: "/profile",
    component: _7b011457,
    name: "profile"
  }, {
    path: "/rooms",
    component: _5f4fa7a6,
    name: "rooms"
  }, {
    path: "/signin",
    component: _789fd5c7,
    name: "signin"
  }, {
    path: "/termsofuse",
    component: _5d5c234e,
    name: "termsofuse"
  }, {
    path: "/unsubscribe",
    component: _a9a2ef08,
    name: "unsubscribe"
  }, {
    path: "/4wg8a/chat",
    component: _3fa6fb9f,
    name: "4wg8a-chat"
  }, {
    path: "/4wg8a/donate",
    component: _7888c114,
    name: "4wg8a-donate"
  }, {
    path: "/4wg8a/feedback",
    component: _604605cc,
    name: "4wg8a-feedback"
  }, {
    path: "/4wg8a/kino",
    component: _6d01af34,
    name: "4wg8a-kino"
  }, {
    path: "/4wg8a/unsubs",
    component: _73c842d3,
    name: "4wg8a-unsubs"
  }, {
    path: "/4wg8a/vmeste",
    component: _4cbb6746,
    name: "4wg8a-vmeste"
  }, {
    path: "/auth/error",
    component: _34933706,
    name: "auth-error"
  }, {
    path: "/auth/success",
    component: _d59ba3fe,
    name: "auth-success"
  }, {
    path: "/profile/favorites",
    component: _10db6648,
    name: "profile-favorites"
  }, {
    path: "/profile/ref",
    component: _fbf51a50,
    name: "profile-ref"
  }, {
    path: "/",
    component: _02bcef86,
    name: "index"
  }, {
    path: "/catalog/:id",
    component: _1a5afe32,
    name: "catalog-id"
  }, {
    path: "/favorites/:id",
    component: _f93c0b26,
    name: "favorites-id"
  }, {
    path: "/name/:id",
    component: _0c611f5e,
    name: "name-id"
  }, {
    path: "/rooms/:id",
    component: _5dbf00de,
    name: "rooms-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
