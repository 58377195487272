<template>
  <main class="error-page">
    <section>
      <div class="terminal">
        <h1>Ошибка <span class="errorcode">{{ error.statusCode }}</span></h1>
        <p class="output">{{ error.message }}</p>

        <template v-if="error.statusCode === 401">
          <div class="auth-social output">
            <span @click="authSocial('google')" class="google"><i /> Google</span>
            <span @click="authSocial('mailru')" class="mailru"><i /> Mail.ru</span>
            <span @click="authSocial('yandex')" class="yandex"><i /> Яндекс</span>
          </div>
        </template>
        <template v-else>
          <p class="output">Вернитесь на <a href="/">Главную страницу</a></p>
          <p class="output">
            Вы можете связаться с нами в <a href="https://t.me/r0n1x">Телеграм</a>
            или <a href="https://vk.com/r0n1x">ВКонтакте</a>,
            а так же по эл.почте <a href="mailto:@support@silverpixel.ru">support@silverpixel.ru</a>
          </p>
        </template>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  props: { error: { type: Object, default: () => ({}) } },
  head() {
    return {
      title: `Упс... Ошибка ${this.error.statusCode} - ${this.error.message}`,
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `Error 404`
        },
        {
          hid: 'description',
          name: 'description',
          content: `Сожалеем, но ${this.error.message}. Воспользуйтесь поиском по сайту или перейдите на главную страницу.`
        }
      ]
    }
  },
  methods: {
    authSocial(social) {
      location.href = `/api/auth/${social}`
    }
  }
}
</script>

<style lang="stylus">
.error-page
  margin: 20vh auto
  color: rgba(128, 255, 128, 0.8); 
  text-shadow:
    0 0 1ex rgba(255, 228, 88, 1),
    0 0 2px rgba(255, 228, 88, 0.8);

  h1
    margin-bottom: 30px

  p
    margin: 15px 0px;

  .auth-social
    grid-template-columns repeat(2, 1fr)

  .terminal {
    box-sizing: inherit;
    max-width: 100%;
    padding: 4rem;
    text-transform: uppercase;
  }

  .output {
    color: rgba(249, 226, 49, 0.8);
    text-shadow:
        0 0 1px rgba(255, 228, 88, 0.4),
        0 0 2px rgba(255, 228, 88, 0.8);
  }

  .output::before {
    content: "> ";
  }

</style>
